import { useRoutes } from "react-router-dom";
import Themeroutes from "./routes/Router";
import Login from "./views/Login";
import useToken from './hooks/useToken';
import './App.css';

const App = () => {
  const { token, setToken, setUser, dropToken } = useToken();

  const routing = useRoutes(Themeroutes(dropToken));
  // const routing = useRoutes(Themeroutes);

  if (!token) {
    return(
      <div className="auth-wrapper">
        <div className="auth-inner">
          <Login setToken={setToken} setUser={setUser} />
        </div>
      </div>
    )
  }


  return <div className="dark">{routing}</div>;
};

export default App;
