import React, { useState, useEffect } from "react";
import { Spinner } from "reactstrap";
import PropTypes from 'prop-types';

import urlApi from '../config/api'

async function loginUser(credentials) {
 return fetch(`${urlApi}login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
   body: JSON.stringify(credentials)
  })
  .then(data => data.json())
}

export default function Login({ setToken, setUser }) {
	const [username, setUserName] = useState();
  const [password, setPassword] = useState();

	const [ loading, setLoading ] = useState(false);

  useEffect(() => {
    setLoading(false)
  }, []);


  const handleSubmit = async e => {
		setLoading(true);
    e.preventDefault();

    try{
      const tokenIntent = await loginUser({
        username,
        password
      });

      switch (tokenIntent.code) {
        case 500:
          alert("Error de servidor")
          setLoading(false);
        break;
  
        case 400:
          //Nombre de usuario o contraseña incorrecto
          alert("Nombre de usuario o contraseña incorrectos")
          setLoading(false);
          break;
        
        case 401:
          // Contrasña incorrecta
          alert("Nombre de usuario o contraseña incorrectos")
          setLoading(false);
          break;
  
        case 200:
          // Éxito
          setToken(tokenIntent);
          setUser(username);
  
          break;
      
        default:
  
          break;
      }

      //setLoading(false);
      return

    } catch {
      alert("Error de servidor, inténtelo más tarde.")
      return
    }

    
    
  }

  return (
	  <form onSubmit={handleSubmit}>
	    <h3>Bienvenido</h3>
      <small>Por favor ingrese sus credenciales para acceder al sistema.</small>
	    <div className="form-group mt-3">
	      <label>Nombre de usuario</label>
	      <input type="text" className="form-control" placeholder="Nombre de usuario" onChange={e => setUserName(e.target.value)} />
	    </div>
	    <div className="form-group mt-2 mb-3">
	      <label>Contraseña</label>
	      <input type="password" className="form-control" placeholder="Contraseña" onChange={e => setPassword(e.target.value)} />
	    </div>
	    <button type="submit" className="btn btn-primary" disabled={loading}>Ingresar</button>
			{
        loading && 
        <Spinner 
          color="secondary"
          className="ms-3 mt-2"
          >
            Cargando...
          </Spinner>
      }
	    
	  </form>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired
}