import { useState } from 'react';

export default function useToken() {
  const getToken = () => {
    const tokenString = sessionStorage.getItem('accessToken');
    try{
      const userToken = JSON.parse(tokenString);
      return userToken?.token
    } catch {
      console.log("Sin token.")
    }
    
    
  };

  const [token, setToken] = useState(getToken());
  const [user, setUser] = useState('');

  const saveToken = userToken => {
    try{
      sessionStorage.setItem('accessToken', JSON.stringify(userToken.accessToken));
      setToken(userToken.accessToken);
      const tokenString = sessionStorage.getItem('accessToken');
      console.log(tokenString)
    } catch{
      console.log("Sin token.")
    }
    
  };

  const dropToken = () => {
    console.log("drop")
    sessionStorage.removeItem('accessToken');
    setToken(null);
    console.log(token)
   }

  return {
    setToken: saveToken,
    setUser: setUser,
    dropToken: dropToken,
    token,
    user
  }
}